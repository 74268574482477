<template>
	<div class="infoCollect">
		<div>
			<el-descriptions
				:column="1"
				v-for="(item1, second) in data"
				:key="second"
				direction='vertical'
				
			>
			<!-- :direction="item1.inputType == 4 ? 'vertical' : ''" -->
				<el-descriptions-item
					:label="'● '+item1.itemName" :label-style="{width:'120px',color:'#444444',fontWeight: 'bold',borderBottom:'8px'}"
				>
					<template
						v-if="item1.inputType == 1 || item1.inputType == 5"
					>
						{{ item1.note }}
					</template>
					<div v-if="item1.inputType == 2 || item1.inputType == 3">
						<div
							v-for="item2 in item1.items"
							:key="'b' + item2.items"
							style="position: relative"
						>
							<template v-if="item2.isPitchUp">
								<i
									v-if="item2.type == 1"
									class="el-icon-success icon-img"
									style="color: #47ab40"
								></i>
								<i
									v-else
									class="el-icon-warning icon-img"
									style="color: #f56c6c"
								></i>
								<!--
                                <img
                                  class="icon-img"
                                  :src="
                                    item2.type == 1
                                      ? require('../../../assets/normal.png')
                                      : require('../../../assets/warning.png')
                                  "
                                /> -->
								<span>{{ item2.content }}</span>
								<div
									class="describ"
									v-if="item2.note"
									style="min-width: 180px"
								>
									{{ item2.note }}
								</div>
								<div class="imgContain" v-if="item2.url">
									<el-image
										v-for="(url, index6) in item2.url.split(
											','
										)"
										:key="index6"
										style="
											width: 100px;
											height: 100px;
											border: 1px solid #dddddd;
											margin: 5px;
										"
										:src="url"
										:preview-src-list="item2.url.split(',')"
										:z-index="index6"
									>
									</el-image>
								</div>
							</template>
							<!-- 关联项 -->
							<template v-for="item3 in item2.junior">
								<div
									v-if="item2.isPitchUp && item3.isPitchUp"
									style="margin-top: 20px;"
									:key="item3.juniorId"
								>
								<!-- margin-left: -125px; -->
									<el-descriptions direction='vertical'>
										<el-descriptions-item
											:label-style="{width:'120px',color:'#444444',fontWeight: 'bold'}"
                                          :label="'● '+item3.juniorName"
										>
											<template
												v-if="
													item3.juniorInputType ==
														1 ||
													item3.juniorInputType == 5
												"
												>{{ item3.note }}
											</template>
											<div
												v-if="
													item3.juniorInputType ==
														2 ||
													item3.juniorInputType == 3
												"
											>
												<div
													v-for="item4 in item3.juniors"
													:key="'b' + item4.juniorsId"
												>
													<template
														v-if="item4.isPitchUp"
													>
														<i
															v-if="
																item4.juniorsType ==
																1
															"
															class="
																el-icon-success
																icon-img
															"
															style="
																color: #47ab40;
															"
														></i>
														<i
															v-else
															class="
																el-icon-warning
																icon-img
															"
															style="
																color: #f56c6c;
															"
														></i>

														{{
															item4.juniorsContent
														}}
														<div
															class="describ"
															v-if="item4.note"
															style="
																min-width: 180px;
															"
														>
															{{ item4.note }}
														</div>
														<div
															class="imgContain"
															v-if="item4.url"
														>
															<el-image
																v-for="(
																	url, index5
																) in item4.url.split(
																	','
																)"
																:key="index5"
																style="
																	width: 100px;
																	height: 100px;
																	border: 1px
																		solid
																		#dddddd;
																	margin: 5px;
																"
																:src="url"
																:preview-src-list="
																	item4.url.split(
																		','
																	)
																"
																:z-index="
																	index5
																"
															>
															</el-image>
														</div>
													</template>
												</div>
											</div>
											<div
												v-if="
													item3.juniorInputType == 4
												"
											>
												<div
													class="imgContain"
													v-if="item3.url"
												>
													<el-image
														v-for="(
															url, index4
														) in item3.url.split(
															','
														)"
														:key="index4"
														style="
															width: 100px;
															height: 100px;
															border: 1px solid
																#dddddd;
															margin: 5px;
														"
														:src="url"
														:preview-src-list="
															item3.url.split(',')
														"
													>
													</el-image>
												</div>
											</div>
										</el-descriptions-item>
									</el-descriptions>
								</div>
							</template>
						</div>
					</div>
					<div class="right" v-if="item1.inputType == 4">
						<div class="imgContain" v-if="item1.url">
							<el-image
								v-for="(url, index3) in item1.url.split(',')"
								:key="index3"
								style="
									width: 100px;
									height: 100px;
									border: 1px solid #dddddd;
									margin: 5px;
								"
								:src="url"
								:preview-src-list="item1.url.split(',')"
							>
							</el-image>
							<!-- <img style=" width: 100px; height: 100px; " :src="thirdlys"  v-for="( thirdlys, int2 ) in item1.url.split( ',' )" @click=" showImg( thirdlys ) " :key="int2" alt="" /> -->
						</div>
					</div>
				</el-descriptions-item>
			</el-descriptions>
		</div>
	</div>
</template>

<script>
	export default {
		name: "infoCollect",
		props: {
			data: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				gsSuccess: require("../../../assets/gsSuccess.png"),
				gsFail: require("../../../assets/gsFail.png"),
			};
		},
		methods: {
			judgeShow(item) {
				// 关联项是否显示判断
				if ((item.inputType == 2 && item.value) || item.isPitchUp) {
					let ele;
					item.items.forEach((inner) => {
						if (inner.items == item.value || inner.isPitchUp) {
							console.log(inner, 777);
							ele = inner.junior;
							// 关联项显示出问题的话ele=true
						}
					});
					return ele && ele.length > 0 ? ele : [];
				} else if (item.inputType == 3) {
					return item.items.some((inner) => {
						return inner.isPitchUp && inner.junior.length > 0;
					});
				}
			},
		},
	};
</script>


<style scoped lang="less">
	.infoCollect {
    padding: 0 40px;
		.titles {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 15px;
			.text {
				font-size: 16px;
				font-weight: bolder;
			}
		}
		.line-list {
			margin-bottom: 10px;
		}
	}
	.pageDetails {
		padding: 10px;
		width: 100%;
		background: #f3f4f7;
	}

	.page {
		// height: 100%;
		padding: 10px;
		height: calc(100vh - 140px);
		overflow: auto;
		background: #fff;

		box-sizing: border-box;

		.pdl20div {
			::v-deep.el-descriptions {
				.el-descriptions__body {
					padding-left: 20px;
				}
			}
		}

		::v-deep {
			.el-form-item {
				margin-bottom: 0;
			}

			.colsite {
				margin-bottom: 20px;
			}
		}

		.create {
			width: 100%;
			height: 50px;
			// background-color: burlywood;
			display: flex;
			flex-direction: row;
			align-items: center;

			.create_size {
				font-size: 20px;
				font-weight: 500;
				color: #252700;
				margin-left: 20px;
			}
		}

		.box {
			width: 96%;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.mapLeft {
				width: 46%;
			}

			.tableRight {
				width: 100%;
				margin-top: 5px;
				margin-right: 25px;

				.btn {
					width: 210px;
					background: #0f74d5;
					border-radius: 4px;
					float: right;
					margin-right: 28%;
				}

				.title {
					padding: 10px;
					margin-bottom: 20px;
				}

				.row {
					padding: 12px 30px 0 30px;
				}
			}
		}
	}

	.right-part {
		padding-left: 30px;
		height: calc(100% - 50px);
		overflow: auto;

		.title {
			font-size: 16px;
			font-weight: 700;
		}

		.right-content {
			padding: 30px;

			.first-line {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;

				div {
					width: 50%;
					margin-bottom: 29px;

					> span {
						color: #545454;
					}

					button {
						padding: 0;
						margin-left: 40px;
					}
				}
			}
		}

		.infoCollect {
			border: 1px solid #ececec;
			padding: 33px;

			.item-title::before {
				content: "| ";
				color: #7cbaff;
			}

			::v-deep.el-descriptions {
				padding: 0px 63px;

				.el-descriptions-item__label:not(.is-bordered-label) {
					color: #545454;
					width: 95px;
				}

				.el-descriptions-item__content {
					padding: 0 0 19px 10px;
					display: block;
					.ques1,
					.ques2 {
						line-height: 30px;
						img {
							width: 16px;
							height: 16px;
							vertical-align: text-top;
						}
					}
					.ques2 {
						padding-left: 20px;
					}
				}
			}
		}

		.imgContain {
			display: flex;
			flex-wrap: wrap;
			.el-image {
				margin: 0 10px 10px 0;
			}
		}

		.userConfirm {
			margin-top: 24px;
			display: flex;

			.left {
				min-width: 66px;
				margin-right: 32px;
			}
		}
	}

	.outer-row {
		height: calc(100% - 50px);

		.el-col {
			height: 100%;
		}

		.el-col:first-child {
			border-right: 1px solid #f1f1f1;
		}
	}

	/deep/ .el-descriptions-item__label {
		width: 70px;
	}

	/deep/ .el-descriptions__table {
		tbody {
			.el-descriptions-item {
				padding-bottom: 5px;
			}
		}
	}

	::v-deep.el-descriptions__title::before,
	.title::before {
		content: "| ";
		font-weight: bold;
		color: #1082ff;
	}
	.icon-img {
		font-size: 15px;
		margin-right: 5px;
	}
	/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell{
		padding-bottom: 5px;
	}
	.describ{
    color: #303133;
        font-size: 14px;
            margin-left: 17px;
  } 
  .imgContain{
	margin: 0 14px;
  }
</style>